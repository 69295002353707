import React from 'react';
import {Link} from 'gatsby';
import {Container, Row, Col} from 'react-bootstrap';
import styles from '../scss/components/_InfoSection.module.scss';
import Plx from 'react-plx';

const InfoSection = ({title = null, paragraph = null, paragraph2 = null,paragraph3 = null, button = null, budsummer = null,mobile = null, link = null}) => {

    const parallaxTitle = [
        {
            start: 'self',
            duration: 500,
            properties: [
              {
                freeze:false,
                startValue: 0,
                endValue: 1,
                property: "opacity"
              },
              {
                startValue: -100,
                endValue: 0,
                property: "translateX"
              },
            ]
          },
    ];

    const parallaxParagraph = [
      {
          start: 'self',
          duration: 300,
          properties: [
            {
              startValue: 0,
              endValue: 1,
              property: "opacity"
            },
            {
              startValue: -100,
              endValue: 0,
              property: "translateX"
            },
          ]
        },
    ];

    const parallaxButton = [
      {
          start: 'self',
          duration: 100,
          properties: [
            {
              startValue: 0,
              endValue: 1,
              property: "opacity"
            },
          {
              startValue: -50,
              endValue: 0,
              property: "translateX"
          },
          ]
        },
    ];
    
    return (
      <Container className={styles.infoContainer}>
        {/* title */}
        {budsummer ? (
          title &&
          <Plx parallaxData={ parallaxTitle } >
              <Row>
                  <Col>
                    <h1 className={styles.infoTitle2}>{title}</h1>
                  </Col>
              </Row>
          </Plx>
        ):(
          title &&
        <Plx parallaxData={ parallaxTitle } >
            <Row>
                <Col>
                  <h1 className={styles.infoTitle}>{title}</h1>
                </Col>
            </Row>
        </Plx>
        )}

        {/* paragraph */}
        {budsummer ? (
           paragraph &&
           <Plx parallaxData={ parallaxParagraph } >
             <Row>
                 <Col className={styles.infoContainerParagraph}>
                   <p className={styles.infoParagraph2}>{paragraph}</p>
                 </Col>
             </Row>
             <Row>
                 <Col className={styles.infoContainerParagraph}>
                   <p className={styles.infoParagraph2}>{paragraph2}</p>
                 </Col>
             </Row>
             <Row>
                 <Col className={styles.infoContainerParagraph}>
                   <p className={styles.infoParagraph2}>{paragraph3}</p>
                 </Col>
             </Row>
         </Plx>
        ):(
          paragraph &&
            <Plx parallaxData={ parallaxParagraph } >
              <Row>
                  <Col className={styles.infoContainerParagraph}>
                    <p className={styles.infoParagraph}>{paragraph}</p>
                  </Col>
              </Row>
              <Row>
                  <Col className={styles.infoContainerParagraph}>
                    <p className={styles.infoParagraph}>{paragraph2}</p>
                  </Col>
              </Row>
              <Row>
                  <Col className={styles.infoContainerParagraph}>
                    <p className={styles.infoParagraph}>{paragraph3}</p>
                  </Col>
              </Row>
          </Plx>
        )}
        
        {/* button */}
        {button &&
          <Plx parallaxData={ parallaxButton } >
            <Row>
                <Col>
                  {link ? (<a href={link} target="_blank"className={styles.infoButton}>{button}</a> ):(<Link to="/producto/" className={styles.infoButton}>{button}</Link>)}
                </Col>
            </Row>
        </Plx>}
        {budsummer &&
           <Plx parallaxData={ parallaxButton } >
           <Row>
               <Col className={styles.infoContainerParagraph} style={{paddingTop: "6%"}}>
                <p className={styles.infoParagraph3}>Jurados:</p>
                {mobile ? <p className={styles.infoParagraph3}>Meme Bouquet | Color<br/>Juani Laborda | Babi Cueto</p> : <p className={styles.infoParagraph3}>Meme Bouquet | Color | Juani Laborda | Babi Cueto</p>}
               </Col>
           </Row>
       </Plx>}
      </Container>
    )
}

export default InfoSection
